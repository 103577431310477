<template>
	<div 
		class="atom-image"
		:class="[
			src == null ? 'no-image' : '',
			absolute ? 'absolute' : '',
			loading ? 'loading' : '',
			cover ? 'cover' : ''
		]"
		>
		<template v-if="src != null">
			<div 
				v-if="cover" 
				class="cover-background-image" 
				:style="{ backgroundImage: 'url(' + src + ')' }"
				/>
			<img 
				v-else
				:src="src" 
				/>
		</template>
		<template v-else>
			<!-- No image available -->
		</template>
	</div>
</template>

<script>
export default {
	name: 'AtomImage',
	props: {
		src: {
			type: String,
			default: null
		},
		absolute: {
			type: Boolean,
			default: false
		},
		loading: {
			type: Boolean,
			default: false
		},
		cover: {
			type: Boolean,
			default: false
		}
	}
}
</script>

<style lang="sass">

.atom-image
	width: 100%
	position: relative
	display: flex

	img
		max-width: 100%
		height: auto
		display: block
		object-fit: cover

	.cover-background-image
		position: absolute
		inset: 0
		background-size: cover
		background-position: center center

	&.no-image
		background-color: lighten($midGrey, 15%)
		display: flex
		justify-content: center
		align-items: center
		background-position: center center
		background-size: 60%
		background-repeat: no-repeat
		background-image: url('~@/assets/images/logo-compex-light-one-colour.svg')
		color: $midGrey
		font-size: 12px
		text-align: center

	&.absolute
		position: absolute
		inset: 0

		img
			position: absolute
			inset: 0

	&.loading
		background: linear-gradient(20deg, lighten($midGrey, 10%) 25%, lighten($midGrey, 15%) 50%, lighten($midGrey, 10%) 75%)
		background-size: 100% 200% 
		animation: gradientLoadingAnimationY 2.6s infinite linear
		position: absolute
		inset: 0

</style>