<template>
	<div class="page-document">

		<div class="featured-image">
			<AtomImage 
				v-if="loading"
				:absolute="true"
				:loading="loading"
				/>
			<AtomImage 
				v-else
				:src="featuredImage"
				:absolute="true"
				:cover="true"
				/>

			<div 
				v-if="!loading && document.name"
				class="title-overlay"
				:class="[
					featuredImage != null ? 'darker' : ''
				]"
				>
				<AtomHeading 
					color="white" 
					align="left"
					size="l"
					weight="normal"
					>
					{{ document.name }}
				</AtomHeading>
			</div>
		</div>

		<div class="container typography-styles">

			<div 
				v-if="loading || !loading && document.description"
				class="document-description typography-styles"
				>
				<AtomLoadingText
					v-if="loading"
					:lines="3"
					/>
				<template v-else>
					{{ document.description }}
				</template>
			</div>

			<div 
				class="chapters-heading"
				:class="{ sticky: chapterHeaderIsSticky }"
				ref="chapterHeading"
				>
				<AtomIcon
					icon="arrow-left"
					color="grey"
					size="medium"
					@click="backToDashboard"
					/>

				<AtomHeading 
					color="grey" 
					align="left"
					size="l"
					weight="normal"
					:spread="true"
					>
					Chapters

					<AtomIcon
						icon="filter"
						color="grey"
						size="small"
						/>
				</AtomHeading>
			</div>

			<div class="chapters-list">
				<template v-if="loading">

				<MoleculeChapterCard 
					v-for="index in 6"
					:key="index"
					:loading="true"
					/>

				</template>
				<template v-else>

					<div 
						v-if="!document.chapters || document.chapters.length === 0"
						class="no-content span-2"
						>
						No chapters available
					</div>

					<template v-else>

						<MoleculeChapterCard 
							v-for="chapter in document.chapters" 
							:key="chapter.id"
							:document="document"
							:chapter="chapter"
							:showDescription="true"
							@click="gotoChapter(chapter)"
							/>

					</template>

				</template>

			</div>			
			
		</div>
	</div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

import AtomLoadingText from '@/components/atoms/AtomLoadingText.vue'
import AtomHeading from '@/components/atoms/AtomHeading.vue'
import AtomIcon from '@/components/atoms/AtomIcon.vue'
import AtomImage from '@/components/atoms/AtomImage.vue'
import MoleculeChapterCard from '@/components/molecules/MoleculeChapterCard.vue'

export default {
	name: 'PageDocument',
	components: {
		AtomLoadingText,
		AtomHeading,
		AtomIcon,
		AtomImage,
		MoleculeChapterCard
	},
	data: () => {
		return {
			loading: true,
			documentID: null,
			chapterHeaderIsSticky: false,
			layoutOuter: null,
			initialChapterHeadingOffset: null,
		}
	},
	mounted() {
		// get the id from the route params
		this.documentID = this.$route.params.id
		this.getDocument(this.documentID).then(() => {
			this.loading = false
		})

		this.layoutOuter = document.querySelector('.layout-main-outer')
		
		this.layoutOuter.scrollTo({ top: 0, behavior: 'smooth' })

		// wait for the scrollTo to finish before adding the scroll event listener - because otherwise the chapter heading will have it's sticky property set to true, but we need to calculate that when the view is at the top.
		setTimeout(() => {
			// add scroll event listener
			this.layoutOuter.addEventListener('scroll', this.isChapterHeadingSticky)

			// add scroll event listener
			this.layoutOuter.addEventListener('scroll', this.isChapterHeadingSticky)

		}, 800)

	},
	beforeUnmount() {
		// remove scroll event listener if it has been added
		if (this.layoutOuter && this.isChapterHeadingSticky) {
			this.layoutOuter.removeEventListener('scroll', this.isChapterHeadingSticky)
		}
	},
	methods: {
		...mapActions('documents', {
			getDocument: 'getDocument',
		}),
		gotoChapter(chapter) {
			// router push to chapter id
			this.$router.push({ name: 'Chapter', params: { id: chapter.id } })
		},
		isChapterHeadingSticky() {
			// see if the chapter heading is above the parent container .layout-main-outer bounds, if so, chapterHeaderIsSticky true, else false
			const chapterHeading = this.$refs.chapterHeading

			if(!chapterHeading){
				return
			}

			if(this.initialChapterHeadingOffset == null){
				this.initialChapterHeadingOffset = chapterHeading.offsetTop
			}else{
				if (chapterHeading && this.layoutOuter) {
					let isSticky = chapterHeading.offsetTop != this.initialChapterHeadingOffset
					this.chapterHeaderIsSticky = isSticky
				}
			}

		},
		backToDashboard(){
			// router push to dashboard
			this.$router.push({ name: 'Dashboard' })
		},
	},
	computed: {
		...mapGetters('documents', {
			document: 'getDocument',
		}),
		featuredImage() {
			if(this.document 
				&& this.document.uploads 
				&& this.document.uploads.length > 0 
				&& this.document.uploads[0].sizes
				&& this.document.uploads[0].sizes.length > 0
				&& this.document.uploads[0].sizes.some(size => size.size_name === 'large')
				){
				const largeSize = this.document.uploads[0].sizes.find(size => size.size_name === 'large');
				if (largeSize) {
					return largeSize.publicUrl;
				}else{
					return null
				}
			}else{
				return null
			}
		}
	}
}
</script>

<style lang="sass">

.page-document
	display: flex
	flex-direction: column
	border-radius: $borderRadiusLarge $borderRadiusLarge 0 0
	background-color: $lightGrey
	width: 100%
	min-height: $mainContentWindowHeight
	overflow: visible
	padding: 0 0 20px 0

	.featured-image
		width: 100%
		height: 230px
		background-color: lighten($midGrey, 15%)
		margin-bottom: 20px
		display: flex
		position: relative
		border-radius: $borderRadiusLarge $borderRadiusLarge 0 0
		overflow: hidden

		.title-overlay
			position: absolute
			bottom: 0
			left: 0
			right: 0
			padding: 60px $sidePaddingSmall 15px $sidePaddingSmall
			background: linear-gradient(0deg, rgba(#333, 0.3), rgba(#333, 0.0))

			&.darker
				background: linear-gradient(0deg, rgba(#333, 0.8), rgba(#333, 0.0))

	.document-description
		display: flex
		flex-direction: column
		gap: 10px
		padding: 0 0 20px 0
		font-size: 14px

	.chapters-heading
		position: sticky
		top: 0
		z-index: 3
		margin: 0 (-$sidePaddingSmall)
		padding: 8px $sidePaddingSmall
		display: flex
		transition: all 300ms
		gap: 10px

		&.sticky
			background-color: rgba($white, 0.75)
			backdrop-filter: blur(5px)

	.chapters-list
		padding: 20px 0
		display: grid
		grid-template-columns: repeat(2, 1fr)
		gap: 12px

		.span-2
			grid-column: span 2


</style>